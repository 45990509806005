<template>
  <div class="app-container">
    <div class="banner"></div>
    <div class="content">
      <div class="intro">
        <div class="title">{{ pageInfo.title }}</div>
        <div class="divide"></div>
        <div class="desc">{{ pageInfo.desc }}</div>
        <img :src="pageInfo.mainPic" alt="" class="main-pic" />
        <div class="design-title">设计原理</div>
        <div class="design-decoration"></div>
        <div class="design-desc">{{ pageInfo.design }}</div>

        <div class="tab-container">
          <el-tabs tab-position="left" class="tabs">
            <el-tab-pane
              v-for="item in functionsList"
              :key="item.title"
              :label="item.title"
            >
              <div class="tab-detail">
                <div
                  class="detail-item"
                  v-for="sub in item.children"
                  :key="sub.img"
                >
                  <img :src="sub.img" alt="" class="item-img" />
                  <div class="item-title">{{ sub.title }}</div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>

        <el-button>免费试用</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageInfo: {
        title: "智慧实验室",
        desc: "依托强大的集成管理云平台，通过分布式的数据采集中心。连接前端各专业智能化系统，以及物联网感知设备，实时准确获取设备运行数据、能源使用数据、环境状态数据等，应用大数据决策分析引擎，进行海量数据的分析、统计、诊断，筛选价值数据进行丰富多元的智能化控制，为实验室提供统一运维管理服务，实现实验室用能管理效率的提高，运维成本的降低，建立统一的应急管理与日常管理，对内与对外服务的管理体系，满足“安全、高效、绿色”的现代实验室建设规划要求。",
        mainPic: require("../../assets/solutions/factory/main.png"),
        design:
          "文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明文字说明",
      },
      functionsList: [
        {
          title: "能耗",
          children: [
            {
              title: "数据远程采集",
              img: require("../../assets/solutions/labo/1-1.png"),
            },
            {
              title: "多维度用能比较",
              img: require("../../assets/solutions/labo/1-2.png"),
            },
            {
              title: "能耗与产能分析",
              img: require("../../assets/solutions/labo/1-3.png"),
            },
            {
              title: "生成报告优化用能",
              img: require("../../assets/solutions/labo/1-4.png"),
            },
          ],
        },
        {
          title: "环境",
          children: [
            {
              title: "数据实时传输",
              img: require("../../assets/solutions/labo/2-1.png"),
            },
            {
              title: "历史曲线",
              img: require("../../assets/solutions/labo/2-2.png"),
            },
            {
              title: "数据总结与分析",
              img: require("../../assets/solutions/labo/1-3.png"),
            },
            {
              title: "自定义监控",
              img: require("../../assets/solutions/labo/2-4.png"),
            },
          ],
        },
        {
          title: "视频",
          children: [
            {
              title: "实时监控",
              img: require("../../assets/solutions/labo/3-1.png"),
            },
            {
              title: "历史记录",
              img: require("../../assets/solutions/labo/2-2.png"),
            },
            {
              title: "多画面展示",
              img: require("../../assets/solutions/labo/3-3.png"),
            },
            {
              title: "语音通话",
              img: require("../../assets/solutions/labo/3-4.png"),
            },
          ],
        },
        {
          title: "人员",
          children: [
            {
              title: "实时定位",
              img: require("../../assets/solutions/labo/4-1.png"),
            },
            {
              title: "轨迹查询",
              img: require("../../assets/solutions/labo/4-2.png"),
            },
            {
              title: "区域人数统计",
              img: require("../../assets/solutions/labo/4-3.png"),
            },
            {
              title: "特殊区域权限自定义",
              img: require("../../assets/solutions/labo/4-4.png"),
            },
            {
              title: "人员信息列表",
              img: require("../../assets/solutions/labo/4-5.png"),
            },
            {
              title: "考勤管理",
              img: require("../../assets/solutions/labo/4-6.png"),
            },
          ],
        },
        {
          title: "设备",
          children: [
            {
              title: "设备状态统计",
              img: require("../../assets/solutions/labo/5-1.png"),
            },
            {
              title: "报警阈值自定义",
              img: require("../../assets/solutions/labo/5-2.png"),
            },
            {
              title: "设备信息列表",
              img: require("../../assets/solutions/labo/5-3.png"),
            },
          ],
        },
        {
          title: "告警",
          children: [
            {
              title: "告警详情",
              img: require("../../assets/solutions/labo/6-1.png"),
            },
            {
              title: "告警类型分析",
              img: require("../../assets/solutions/labo/6-2.png"),
            },
            {
              title: "处理情况",
              img: require("../../assets/solutions/labo/6-3.png"),
            },
          ],
        },
        {
          title: "业务",
          children: [
            {
              title: "实验管理",
              img: require("../../assets/solutions/labo/7-1.png"),
            },
            {
              title: "出入库管理",
              img: require("../../assets/solutions/labo/7-2.png"),
            },
            {
              title: "仪器管理",
              img: require("../../assets/solutions/labo/7-3.png"),
            },
          ],
        },
        {
          title: "房间",
          children: [
            {
              title: "房间信息列表",
              img: require("../../assets/solutions/labo/8-1.png"),
            },
            {
              title: "权限设置",
              img: require("../../assets/solutions/labo/8-2.png"),
            },
          ],
        },
        {
          title: "门禁",
          children: [
            {
              title: "门禁信息列表",
              img: require("../../assets/solutions/labo/9-1.png"),
            },
            {
              title: "权限设置",
              img: require("../../assets/solutions/labo/9-2.png"),
            },
          ],
        },
        {
          title: "可视化系统",
          children: [
            {
              title: "电视大屏",
              img: require("../../assets/solutions/labo/10-1.png"),
            },
            {
              title: "房间电子屏",
              img: require("../../assets/solutions/labo/10-2.png"),
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@function vw($px) {
  @return ($px / 1920) * 100vw;
}

.app-container {
  .banner {
    height: vw(516);
    width: 100%;
    background: url("~@/assets/solutions/labo/banner.png") no-repeat center;
    background-size: cover;
  }

  .content {
    margin-top: vw(80);
    margin-bottom: vw(119);
    padding: 0 vw(369);
    display: flex;
    flex-direction: column;
    .intro {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: vw(24);
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        align-self: flex-start;
        margin-bottom: vw(30);
      }
      .divide {
        width: vw(1180);
        height: 1px;
        background: #dadada;
        margin-bottom: vw(32);
      }
      .desc {
        font-size: vw(14);
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        text-align: start;
        line-height: vw(30);
        margin-bottom: vw(30);
      }
      .main-pic {
        width: vw(680);
        height: vw(373);
        margin-bottom: vw(60);
      }
      .design-title {
        font-size: vw(16);
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-bottom: vw(19);
      }
      .design-decoration {
        width: vw(34);
        height: vw(2);
        background: #ff9a4f;
        border-radius: 1px;
        align-self: center;
        margin-bottom: vw(39);
      }
      .design-desc {
        font-size: vw(14);
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: vw(30);
        text-align: start;
        margin-bottom: vw(102);
      }
      .tab-container {
        width: vw(1180);
        box-shadow: 0px 0px 20px 0px rgba(58, 54, 54, 0.16);
        border-radius: vw(14);
        margin-bottom: vw(60);

        .tabs {
          padding: vw(50);
          .tab-detail {
            display: flex;
            flex-wrap: wrap;
            .detail-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: vw(80);
              margin-bottom: vw(30);
              .item-img {
                width: vw(96);
                height: vw(96);
                margin-bottom: vw(19);
              }

              .item-title {
                font-size: vw(14);
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
              }
            }
          }
        }
      }

      ::v-deep .el-tabs__item {
        text-align: left;
        font-weight: bold;
        &:hover{
            color: rgba(255, 130, 37, 1);
        }
      }

      ::v-deep .el-tabs__item.is-active {
        color: rgba(255, 130, 37, 1);
      }

      ::v-deep .el-tabs__active-bar {
        background-color: rgba(255, 130, 37, 1);
      }

      ::v-deep .el-button {
        border: 1px solid #ff8225;
        color: #ff8225;
        background: #fff;
        &:hover {
          background: #ff8225;
          color: #fff;
        }
      }
    }
  }
}
</style>